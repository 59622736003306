import React from "react"
import ReactDOM from "react-dom"
import "./global.css"
import App from "./App"
import reportWebVitals from './reportWebVitals';
import { BaseProvider, LightTheme } from "baseui";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import clevertap from 'clevertap-web-sdk';


import "devices.css";
import "./styles.scss";

let Clevertap = clevertap
Clevertap.privacy.push({ optOut: false });
Clevertap.init('ZWW-WWW-WW4Z', 'in1')
Clevertap.setLogLevel(3);
window.clevertap = Clevertap

const rootElement = document.getElementById("root");

const theme = {
  ...LightTheme,
  mediaQuery: {
    small: `@media screen and (min-width: ${LightTheme.breakpoints.small}px)`,
    medium: `@media screen and (min-width: ${LightTheme.breakpoints.medium}px)`,
    large: `@media screen and (min-width: ${LightTheme.breakpoints.large}px)`,
    maxSmall: `@media screen and (max-width: ${LightTheme.breakpoints.medium - 1
      }px)`,
    maxMedium: `@media screen and (max-width: ${LightTheme.breakpoints.large - 1
      }px)`
  }
};


export const makeid = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}
let receiver = makeid(13)

const engine = new Styletron();

ReactDOM.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <App receiver={receiver} />
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// ReactDOM.render(<App />, document.getElementById("root"))
