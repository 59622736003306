import { useSpring, useTransition } from "@react-spring/core"
import React, { useState, useEffect } from "react"
import { MdContentCopy,MdShare } from "react-icons/md"
import { useLocation,Link } from "wouter"
import Receiver from "./Receiver"
import { Box, Container, Jumbo, Line, Nav } from "./Styles"

import Breadcrumb from "./Breadcrumb";
export const NumberContext = React.createContext();


const jumbo = {
  "/": ["Let’s tailor this for your industry"],
  "/hyperLocal": ["Select Role"],
  "/ecommerce":["Tell us what you do"],
  "/fooddelivery": ["Select Role"],
  "/ride": ["Tell us what you do"],
  "/finTech": ["Tell us what you do"],
  "/delivery": ["Tell us what you do"],
  "/enter": ["To experience the Signed Call™ as a Call Receiver"],
  '/personalized': ["Get In-App", "Call experience"],
  "/merchant": ["Get In-App", "Call experience"],
  '/deliveryBoy': ["Select Role"],
  '/restaurant': ["Select Role"],
  '/bookedFor': ["Select Role"],
  '/drivers': ["Select Role"],
  '/bankStaff': ["Select Role"],
}

function Text({ children, opacity, background, location }) {
  return (
    <>
      {location === "/personalized" ?
        <Box className="personalized-box" style={{ opacity }}>
          {React.Children.toArray(children).map((text, index) => (
            <Line key={index} style={{ transform: opacity.to((t) => `translate3d(0,${index * -50 + (1 - t) * ((1 + index) * 40)}px,0)`) }}>
              <div>{text}</div>
            </Line>
          ))}
        </Box>
        : location === "/enter" ? <Box style={{ opacity, top: "25%" }}>
          {React.Children.toArray(children).map((text, index) => (
            <Line key={index} style={{ transform: opacity.to((t) => `translate3d(0,${index * -50 + (1 - t) * ((1 + index) * 40)}px,0)`) }}>
              <div>{text}</div>
            </Line>
          ))}
        </Box> :
          <Box style={{ opacity }}>
            {React.Children.toArray(children).map((text, index) => (
              <Line key={index} style={{ transform: opacity.to((t) => `translate3d(0,${index * 0 + (1 - t) * ((1 + index) * 40)}px,0)`), lineHeight: '1.3em', lineHeight: '1.3em' }}>
                <div>{text}</div>
              </Line>
            ))}
          </Box>
      }
    </>
  )
}

const Separator = ({ children, ...props }) => (
  <span style={{ color: "blue" }} {...props}>
    {children}
  </span>
);

const options = {
  items: [
    { to: "/", label: "Home" },
    { to: "/delivery", label: "Role" },
    { to: "/personalized", label: "Personalization" },
    { to: "/enter", label: "Receiver's Link" }
  ]
};

export default function App({ receiver }) {
  let codeSnippet
  const [url, setUrl] = useState('');
  const [note, setNote] = useState('');
  const [hexcode, setHexcode] = useState('');
  const [share, setShare] = useState(false);


  let staging = window.location.href.split('/');
  staging.pop();
  staging = staging.join('/');
  codeSnippet = `${staging}/receiver?cuid=${receiver}&hex=${hexcode ? hexcode.replace('#', '') : null}&url=${url}`;
  const [location] = useLocation()

  useEffect(() => {
    if (!hexcode && url == ''){
      setNote('(Note : Please go to previous page to get personalised receiver link)')
    }else{
      setNote('')
    }
  },[])
 
  const props = useSpring({
    background: location === "/" ? "#ffffff" : location ==='/personalized'? "#ffffff": location === "/ecommerce" ? "#ffffff" : location === "/delivery" ? "#ffffff" : location === "/finTech" ? "#ffffff" : location === "/enter" ? "#ffffff" : location === "/ride" ? "#ffffff" : "#ffffff",
    color : location === "/" ? "#133B58"  : location ==='/personalized'? "#133B58": location === "/hyperLocal" ? "#133B58" : location === "/fooddelivery" ? "#133B58" : location === "/finTech" ? "#133B58" : location === "/enter" ? "#133B58" : location === "/ride" ? "#133B58" : "#133B58"
  })
  // Animated shape props
  const transition = useTransition(location, {
    from: { position: [0, 0, -20], rotation: [0, Math.PI, 0], scale: [0, 0, 0], opacity: 0 },
    enter: { position: [0, 0, 0], rotation: [0, 0, 0], scale: [1, 1, 1], opacity: 1 },
    leave: { position: [0, 0, -10], rotation: [0, -Math.PI, 0], scale: [0, 0, 0], opacity: 0 },
    config: () => (n) => n === "opacity" && { friction: 60 },
  })

  const handleHexCode = (val) => {
    setHexcode(val)
  }

  const handleUrl = (val) => {
    setUrl(val)
  }

  const handleShare = () =>{
    setShare(!share)
  }

  const CopyComponent = ({ clickFn }) => {
    const [isCopied, setIsCopied] = useState(false)
    return (
      <span className="codespan">{isCopied ? "Copied!" : <MdContentCopy onClick={() => {
        clickFn();
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 1500);

      }} cursor={'pointer'} />}</span>
    )
  }

  const onCopyText = async () => {
    await navigator.clipboard.writeText(codeSnippet)
  };

  const dynamicColor = () => {
    // console.log('check function call', location)
    switch(location){
      case "/":
      case "/personalized":
        return {color:"#3A3E3B"}
      case "/hyperLocal":
      case "/delivery":
      case "/ride":
      case "/finTech":
      case "/enter":
      case "/ecommerce" :
        return {color:"white"}
    }
  }


  return (
    <>
    <div className="breadcrumb">
          <Breadcrumb location={location} separator={<Separator>/</Separator>}>
            {options.items.map(({ to, label }) => {
              // const Icon = options.icons[label];
              return (
                <div key={to} className="some-custom-classname" style={dynamicColor()}>
                  {/* {Icon && <Icon />} */}
                  <Link to={to}>{label}</Link>
                </div>
              );
            })}
          </Breadcrumb>
          </div>

      {location === "/receiver" ? <Receiver /> :
        <>
          <Container style={{ ...props }}>
            <Jumbo>
              {transition((style, location) =>
                <Text location={location} open={true} t={style.t} opacity={style.opacity} background={props.background} children={jumbo[location]} style={{ lineHeight: '1.3em' }} />
              )}
            </Jumbo>
            {location === "/register" ?
              <>
              <input className="hiddenMagicLink" type="hidden" value={codeSnippet} />
              </>
              : null}
            {location === "/enter" ?
              <>
                <div className="container_new code-snippet-wrap">
                  <div className="code-snippet">
                    <div className="code-section">
                      <pre ><a href={codeSnippet} target="_blank" >{codeSnippet}</a></pre>
                      <small className="linkContext">(This is a sharable link to receive a call. Request you to copy this link and share/open in a new window)</small>
                      <br/>
                      {hexcode ? (url ? '' : <small className="note">{note}</small>) : <small className="note" >{note}</small>}
                      
                    </div>
                    <CopyComponent clickFn={onCopyText} />
                    <MdShare onClick={handleShare} style={{overflow:'visible'}}/>
                    {share?
                    <>
                    <div className="overlay">
                      <div className="popup">
                        <h2>Share</h2>
                        <a className="close" onClick={()=>setShare(false)} href="#">&times;</a>
                        <div className="content"> 
                        <div className="icons">
                        { <EmailShareButton 
                        url ={codeSnippet}
                        >
                        <EmailIcon  size={42} round={true} />
                        </EmailShareButton> }{
                        <WhatsappShareButton 
                        url ={codeSnippet}
                        >
                        <WhatsappIcon  size={42} round={true} />
                        </WhatsappShareButton> }
                        </div>
                      <div className="copytext"><div className="codesnippet">{codeSnippet}</div> <div className="copybtn"><CopyComponent clickFn={onCopyText} /></div>  </div>
                        {/* <ShareSocial 
                          style={style_new}
                          url ={codeSnippet}
                          socialTypes={['facebook','twitter','reddit','linkedin']}
                          /> */}
                        </div>
                      </div>
                    </div>
                    </>
                    :null
                    }
                  </div>
                </div>
              </>
              : null}
          </Container>
         
          {location === "/personalized" ? <Nav className={location === "/personalized"? 'personalizedNav':location === "/enter"?'enterNav': ''} sethexcode={handleHexCode} seturl={handleUrl} url={url} hexcode={hexcode} location={location} style={{ color: props.color }} /> :
            <Nav sethexcode={handleHexCode} seturl={handleUrl} url={url} hexcode={hexcode} location={location} style={{ color: props.color }} receiver={receiver} />}
          
        </>}
    </>
  )
}
